
.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-900 { font-weight: 900 !important; }

.f-gray {
  color: #C0C4CB !important;
}
.f-gray-dark {
  color: #53575F !important;
}

.fc-white {
  color: white !important;
}

.fc-black {
  color: #000000 !important
}

.text-underlined {
  text-decoration: underline !important;
}

.text-no-underline {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-center {
  text-align: center !important;
}

.align-justify {
  text-align: justify !important;
}

.rounded {
  border-radius: 30px !important;
}
.rounded-15 {
  border-radius: 15px !important;
}
.rounded-8 {
  border-radius: 8px !important;
}
.rounded-0 {
  border-radius: 0px !important;
}

/** Margin */
.ml-auto{
  margin-left: auto !important;
}
.mr-auto{
  margin-right: auto !important;
}

.ml-0{
  margin-left: 0px !important;
}
.mr-0{
  margin-right: 0px !important;
}
/*Margin*/
.mt-5{
margin-top: 5px !important;
}
.mt-10{
margin-top: 10px !important;
}
.mt-15{
margin-top: 15px !important;
}
.mt-20{
margin-top: 20px  !important;
}
.mt-30{
margin-top: 30px !important;
}
.mt-60{
margin-top: 60px !important;
}
.mb-5{
margin-bottom: 5px !important;
}
.mb-10{
margin-bottom: 10px !important;
}
.mb-15{
margin-bottom: 15px !important;
}
.mb-20{
margin-bottom: 20px !important;
}
.mb-30{
margin-bottom: 30px !important;
}
.mb-60{
margin-bottom: 60px !important;
}
.mr-5{
margin-right: 5px !important;
}
.mr-10{
margin-right: 10px !important;
}
.mr-20{
margin-right: 20px !important;
}
.mr-30{
margin-right: 30px !important;
}
.mr-60{
margin-right: 60px !important;
}

.ml-5{
margin-left: 5px !important;
}
.ml-10{
margin-left: 10px !important;
}
.ml-30{
margin-left: 30px !important;
}
.ml-60{
margin-left: 60px !important;
}

.pt-20{
padding-top: 20px !important;
}
.pl-20{
  padding-left: 20px !important;
}
.p-20{
padding: 20px !important;
}

.pt-30{
  padding-top: 30px !important;
}
.pl-30{
  padding-left: 30px !important;
}
.p-30{
  padding: 30px !important;
}

.w-auto{
width: auto !important;
}
.w-320{
width: 320px !important;
}
.w-240{
width: 240px !important;
}
.w-120{
width: 120px !important;
}
.w-60{
width: 60px !important;
}

.w-30p{
width: 30% !important;
}
.w-50p{
width: 50% !important;
}
.w-60p{
width: 60% !important;
}
.w-80p{
  width: 80% !important;
}
.w-100p{
  width: 100% !important;
}

.h-20{
height: 20px !important;
}

/** Float */
.float-left{
  float: left !important;
}
.float-right{
  float: right !important;
}
.float-none{
  float: none !important;
}

.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-bold{
  font-weight: bold !important;
}
.text-capitalize{
  text-transform: capitalize;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.letter-spacing-3 {
  letter-spacing: 3px !important;
}

.letter-spacing-4 {
  letter-spacing: 4px !important;
}

.no-border-radius {
  border-radius: 0px !important;
}