/** Colors variables **/
:root {
  --cs-aztecGold: #CA9B52;
  --cs-chinaRose: #AD5068;
  --cs-white: #FFFFFF;
  --cs-black: #111111;
  --cs-cream: #FAF7F4;
  --cs-gray: #9098B1;
  --cs-red: #971033;
  --cs-gray-blue: #EBF0FF;
  --cs-gray-blue-light: #F2F2F2;
  --cs-red-main: #9E0303;
  --cs-philippineGray: '#898A8D';
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Ubuntu, sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--cs-cream) !important;
}

:not(input) {
  color: var(--cs-black);
}

span, :not(.MuiCheckbox-root) {
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-bolder {
  font-weight: bolder !important;
}
.text500 {
  font-weight: 500 !important;
}
.opacity9 {
  opacity: 0.9;
}
.opacity5 {
  opacity: 0.5;
}
.letterspacing3 {
  letter-spacing: .3px !important;
}
.text-center {
  text-align: center !important;
}
.text-underline {
  text-decoration: underline !important;
  cursor: pointer;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-gray {
  color: #ababab;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.full-width {
  width: 100% !important;
}
.font11 {
  font-size: 11px !important;
}
.font12 {
  font-size: 12px !important;
}
.font10 {
  font-size: 10px !important;
}
.text-no-underline {
  text-decoration: none !important;
}
.img-circle {
  border-radius: 50% !important;
}
.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
}
.mapboxgl-ctrl-geocoder--icon {
  display: none;
}

.imageHeader {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.imageHeader video {
  object-fit: cover;
}

.videoHeader {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

.MuiPickerStaticWrapper-root .MuiDateRangePickerDay-dayInsideRangeInterval {
  background-color: #E88963 !important
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1.5px var(--cs-gray);
}

::-webkit-scrollbar-thumb {
  background: var(--cs-aztecGold);
  /*border-radius: 4px;*/
}

::-webkit-scrollbar-thumb:hover {
  background: var(--cs-chinaRose);
}

.display-inline-block {
  display: inline-block !important;
}

.transition-all {
  transition: all .3s ease;
}

.transition-all-3s {
  transition: all .3s ease;
}

.zoom-in-on-hover:hover {
  transform: scale(1.1);
}

.zoom-in-on-hover-one-dot-zero-five:hover {
  transform: scale(1.05);
}

.zoom-in-on-press:active {
  transform: scale(1.2);
}

.box-shadow-on-hover:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.text-color-gold {
  color: var(--cs-aztecGold)!important
}

.text-color-burgundy {
  color: var(--cs-chinaRose)!important
}

.text-color-white {
  color: var(--cs-white)!important
}

.text-color-black {
  color: var(--cs-black)!important
}

.text-color-cream {
  color: var(--cs-cream)!important
}

.text-color-gray {
  color: var(--cs-cream)!important
}

.text-color-red {
  color: var(--cs-red)!important
}

.text-color-gray-blue {
  color: var(--cs-gray-blue)!important
}

.text-color-gray-blue-light {
  color: var(--cs-gray-blue-light)!important
}

.text-color-red-main {
  color: var(--cs-red-main)!important
}

.a-active-color-gold:visited {
  color: var(--cs-aztecGold) !important;
}

.a-active-color-red:visited {
  color: var(--cs-red) !important;
}

.a-active-color-white:visited {
  color: var(--cs-white) !important;
}

.a-active-color-grey:visited {
  color: var(--cs-gray) !important;
}

.full-height {
  height: 100% !important;
}

.color-gold-on-hover:hover {
  color: var(--cs-aztecGold) !important;
}

.color-red-on-hover:hover {
  color: var(--cs-red) !important;
}

.color-burgundy-on-hover:hover {
  color: var(--cs-chinaRose) !important;
}
.bold-on-hover:hover{
  font-weight: 700;
}
.color-white-on-hover:hover {
  color: var(--cs-white) !important;
}

.color-grey-on-hover:hover {
  color: var(--cs-gray) !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}
